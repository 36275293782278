import React from 'react';
import Routes from 'routes/Routes';

const App: React.FC = () => (
  <div className="App">
    <Routes />
  </div>
);

export default App;
